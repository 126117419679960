<template>
  <BrToolBar @search="buscar" :configFilter="configFilter">
    <Modal />
  </BrToolBar>
</template>

<script>
/**
 * MIXIN
 */
import toolbar from '@/views/mixins/toolbar'

export default {
  name: `BrToolBarCadastroRole`,
  mixins: [toolbar],
  components: { 
    Modal: () => import('./modalCadastro'),
    BrToolBar: () => import('@/views/components/toolbar')
  }
}
</script>